import * as React from "react";

const FourOFour: React.SFC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="128pt" height="128pt" viewBox="0 0 128 128">
      <path
        d="M124.08594 20.39844V87.2539c0 6.71484-5.44531 12.15625-12.15625 12.15625H16.0703c-6.71093 0-12.15625-5.44141-12.15625-12.15625V20.39844c0-6.71485 5.44532-12.15625 12.15625-12.15625h95.85938c6.71094 0 12.15625 5.4414 12.15625 12.15625zm0 0"
        fill="#fff5ea"
      />
      <path
        d="M124.08594 20.39844V87.2539c0 6.71484-5.44531 12.15625-12.15625 12.15625H64V8.24219h47.92969c6.71094 0 12.15625 5.4414 12.15625 12.15625zm0 0"
        fill="#f2e1df"
      />
      <path
        d="M111.82031 4.5H16.1797C7.2578 4.5 0 11.75 0 20.66406v66.32422c0 8.91016 7.25781 16.16406 16.17969 16.16406h5.19922c2.71484 0 4.91796 2.19922 4.91796 4.91407 0 3.0078 1.21876 5.73437 3.1875 7.70703 1.97266 1.97265 4.69922 3.1914 7.70704 3.1914h53.61718c6.01954 0 10.89454-4.8789 10.89454-10.89843 0-1.35547.55078-2.58594 1.4414-3.47266.89063-.89063 2.11719-1.4414 3.47656-1.4414h5.19922c8.92188 0 16.17969-7.25391 16.17969-16.16407V20.66406C128 11.75 120.74219 4.5 111.82031 4.5zM120.5 86.98828c0 4.77734-3.89063 8.66406-8.67969 8.66406H16.1797c-4.78907 0-8.67969-3.88671-8.67969-8.66406V20.66406C7.5 15.88672 11.39063 12 16.17969 12h28.17187c0 4.8789 3.96875 8.85156 8.85157 8.85156h21.59374c4.88282 0 8.85157-3.97265 8.85157-8.85156h28.17187c4.78906 0 8.67969 3.88672 8.67969 8.66406zm0 0"
        fill="#0085a9"
      />
      <path
        d="M128 20.66406v66.32422c0 8.91016-7.25781 16.16406-16.17969 16.16406h-5.19922c-1.35937 0-2.58593.55079-3.47656 1.44141-.89062.88672-1.4414 2.11719-1.4414 3.47266 0 6.01953-4.875 10.89843-10.89454 10.89843H64v-23.3125h47.82031c4.78906 0 8.67969-3.88671 8.67969-8.66406V20.66406C120.5 15.88672 116.60937 12 111.82031 12H83.64844c0 4.8789-3.96875 8.85156-8.85156 8.85156H64V4.5h47.82031C120.7422 4.5 128 11.75 128 20.66406zm0 0"
        fill="#00608d"
      />
      <path
        d="M128 109.625c0 7.52344-6.10938 13.625-13.64063 13.625H13.64064c-3.76563 0-7.17579-1.52344-9.64454-3.99219C1.52734 116.79687 0 113.38671 0 109.625c0-3.57422 2.89844-6.46875 6.47656-6.46875h31.375c.66407 0 1.26563.26953 1.70313.70313.4336.43359.70312 1.03515.70312 1.69921 0 3.08204 2.5 5.58204 5.58594 5.58204h36.3125c3.08594 0 5.58594-2.5 5.58594-5.58204 0-1.32812 1.07812-2.40234 2.40625-2.40234h31.375c1.78906 0 3.40625.72266 4.57812 1.89453C127.27344 106.21875 128 107.83594 128 109.625zm0 0"
        fill="#2999ff"
      />
      <path
        d="M128 109.625c0 7.52344-6.10938 13.625-13.64063 13.625H64v-12.10938h18.15625c3.08594 0 5.58594-2.5 5.58594-5.58203 0-1.32812 1.07812-2.40234 2.40625-2.40234h31.375c1.78906 0 3.40625.72266 4.57812 1.89453C127.27344 106.21875 128 107.83594 128 109.625zm0 0"
        fill="#3e7eff"
      />
      <path
        d="M112.94531 32.5625v51.58984c0 2.21875-1.80469 4.02735-4.02344 4.02735H19.07814c-2.21876 0-4.02344-1.8086-4.02344-4.02735V32.5625c0-2.21875 1.80468-4.02734 4.02343-4.02734h89.84376c2.21874 0 4.02343 1.80859 4.02343 4.02734zm0 0"
        fill="#ffc837"
      />
      <path
        d="M112.94531 32.5625v51.58984c0 2.21875-1.80469 4.02735-4.02344 4.02735H64V28.53516h44.92188c2.21874 0 4.02343 1.80859 4.02343 4.02734zm0 0"
        fill="#fbb13e"
      />
      <path
        d="M64 43.66797c-5.66797 0-10.27734 4.60937-10.27734 10.27734v10.07032c0 5.66796 4.60937 10.28124 10.27734 10.28124s10.28125-4.61328 10.28125-10.28124V53.9453c0-5.66797-4.61328-10.27734-10.28125-10.27734zm2.78125 20.34766c0 1.53515-1.25 2.78124-2.78125 2.78124s-2.77734-1.24609-2.77734-2.78124V53.9453c0-1.53125 1.24609-2.77734 2.77734-2.77734 1.53125 0 2.78125 1.2461 2.78125 2.77734zm0 0M46.05469 43.66797c-2.07032 0-3.75 1.67969-3.75 3.75v9.3086h-5.5586v-9.3086c0-2.07031-1.67968-3.75-3.75-3.75-2.0703 0-3.75 1.67969-3.75 3.75v9.64062c0 3.95313 3.21485 7.16797 7.16797 7.16797h5.89063v6.32031c0 2.07032 1.67968 3.75 3.75 3.75 2.07031 0 3.75-1.67968 3.75-3.75v-23.1289c0-2.07031-1.67969-3.75-3.75-3.75zm0 0"
        fill="#fff"
      />
      <path
        d="M95.0039 43.66797c-2.07421 0-3.75 1.67969-3.75 3.75v9.3086h-5.55859v-9.3086c0-2.07031-1.67969-3.75-3.75-3.75s-3.75 1.67969-3.75 3.75v9.64062c0 3.95313 3.21485 7.16797 7.16406 7.16797h5.89454v6.32031c0 2.07032 1.67578 3.75 3.75 3.75 2.0703 0 3.75-1.67968 3.75-3.75v-23.1289c0-2.07031-1.6797-3.75-3.75-3.75zm0 0M74.28125 53.94531v10.07032c0 5.66796-4.61328 10.28124-10.28125 10.28124v-7.5c1.53125 0 2.78125-1.24609 2.78125-2.78124V53.9453c0-1.53125-1.25-2.77734-2.78125-2.77734v-7.5c5.66797 0 10.28125 4.60937 10.28125 10.27734zm0 0"
        fill="#fff5ea"
      />
    </svg>
  );
};

export default FourOFour;
