import * as React from "react";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { FourOFour } from "../../components/elements/icons";

import "./FourOFourPage.css";

const FourOFourPage: React.SFC = (): JSX.Element => {
  return (
    <Container className="four-o-four-container">
      <Row className="justify-content-md-center">
        <Col md="auto" style={{ flexDirection: "row", display: "flex" }}>
          <FourOFour />
          <Card style={{ width: "18rem" }} className="four-o-four-card">
            <Card.Body>
              <h3>
                Error <strong>404</strong>
              </h3>
              <Card.Text>
                Sorry, the page you were looking for does not exist. Try going to{" "}
                <a href="/">
                  <strong>
                    <code>whitelist.sh</code>
                  </strong>
                </a>.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FourOFourPage;
