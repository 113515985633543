import React from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { Error } from "./error";
import { Feed } from "./feed";
import { Pitch } from "./pitch";

interface UserSettings {
  start: number;
  end: number;
  feed_start: number;
  feed_end: number;
}

interface RootProps {
  loggedIn: boolean;
  userSettings: UserSettings | null;
  updateCurrentWhitelistUser: any;
  updateUserSettings: any;
}

interface RootState {
  currentUser?: any;
  items: any[];
  loading: boolean;
  error: boolean;
}

const DEV_API_BASE = "http://127.0.0.1:8080/v1/";
const PROD_API_BASE = "https://api.whitelist.sh/v1/";
export const API_BASE = process.env.NODE_ENV === "development" ? DEV_API_BASE : PROD_API_BASE;
export const USER_SETTINGS_LOCAL_STORAGE_KEY_PREFIX = "usersettings";
const FEED_WINDOW_IN_SECS = 60 * 60 * 24 * 14;

const progressToFeedStartTimestamp = (start: number, end: number, progress: number) => {
  return start + Math.ceil((end - start) * (progress / 100));
};

async function fetchWithTimeout(resource: any, options: any) {
  const { timeout = 8000 } = options;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}

class Root extends React.Component<RootProps, RootState> {
  constructor(props: any) {
    super(props);
    this.state = {
      items: [],
      loading: false,
      error: false,
    };

    this.fetchFeedItems = this.fetchFeedItems.bind(this);
    this.updateFeedItems = this.updateFeedItems.bind(this);
  }

  callToAction = () => {
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={10}>
            <h4 style={{ textAlign: "left" }}>
              Whitelist takes you from start-to-finish through a core computer science and software engineering
              curriculum based on world-class college programs such as Stanford University, University of Waterloo, and
              MIT.
            </h4>
          </Col>
        </Row>
        <Row className="justify-content-md-center" style={{ marginTop: "3%" }}>
          <Col lg={10}>
            <Link style={{ color: "white" }} to="/signup">
              <Button variant="primary" size="lg">
                Start Your Journey With The List
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  };

  async fetchFeedItems(start: number, end: number, feedStart: number, feedEnd: number) {
    const feedUrl = `${API_BASE}timelist/feed/start/${start}/end/${end}/feed_start/${feedStart}/feed_end/${feedEnd}`;
    const response = await fetchWithTimeout(
      feedUrl,
      {
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        timeout: 10000,
      },
    );
    return await response.json();
  }

  /**
   * Take a progress value and translate it into a `feed_start` value.
   */
  updateFeedItems(startDate: Date, endDate: Date, progress: number, setIsLoading: any) {
    setIsLoading(true);
    const start = Math.floor(startDate.getTime() / 1000);
    const end = Math.floor(endDate.getTime() / 1000);
    const feedStart = progressToFeedStartTimestamp(start, end, progress);
    const feedEnd = feedStart + FEED_WINDOW_IN_SECS;
    this.fetchFeedItems(
      start,
      end,
      feedStart,
      feedEnd,
    ).then((data) => {
      setIsLoading(false);
      this.setState({
        items: data,
        loading: false,
      });
    }).catch(err => {
      this.setState({
        error: true,
      });
    });
  }

  render() {
    const { loading, error } = this.state;

    return (
      <div>
        {this.props.loggedIn ? null : <Pitch />}
        {error
          ? <Error />
          : (
            <Feed
              userSettings={this.props.userSettings}
              updateFeedItems={this.updateFeedItems}
              items={this.state.items}
              loading={loading}
              loggedIn={this.props.loggedIn}
              listStart={this.props.userSettings?.start}
              updateCurrentWhitelistUser={this.props.updateCurrentWhitelistUser}
              updateUserSettings={this.props.updateUserSettings}
            />
          )}
        {this.props.loggedIn ? null : this.callToAction()}
      </div>
    );
  }
}

export default Root;
