import * as React from "react";
import { useEffect, useState } from "react";

import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";

import "./Feed.css";
import { ButtonGroup } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { BrandColours } from "../../types";
import { Item as ItemType } from "../../types/Domain";
import { Item as ItemComponent } from "../item";

interface Item {
  name: string;
}

interface UserSettings {
  start: number;
  end: number;
  feed_start: number;
  feed_end: number;
}

interface FeedProps {
  userSettings: UserSettings | null;
  items: Item[];
  updateFeedItems: any;
  loading: boolean;
  loggedIn: boolean;
  listStart: number | null | undefined;
  updateCurrentWhitelistUser: any;
  updateUserSettings: any;
}

interface ArticleListProps {
  items: IterableIterator<ItemType>;
}

const LENGTH_OF_MVP_TIMELIST_IN_SECS = 60 * 60 * 24 * 365 * 4;
const DEFAULT_LOGGED_OUT_ACTIVE_DAY = new Date(2023, 3, 20, 0, 0, 0, 0);
const MAX_ITEMS_IN_FEED = 50;

const ArticleList = (props: ArticleListProps) => {
  return (
    <div style={{ backgroundColor: "white" }}>
      {Array.from(props.items).map((item: ItemType) => (
        <ItemComponent
          key={item.link}
          id={item.id}
          title={item.title}
          link={item.link}
          period={item.period}
          annotations={new Map(Object.entries(item.annotations))}
          description={item.description}
          schedule_time={item.schedule_time}
        />
      ))}
    </div>
  );
};

const clamp = (num: number, min: number, max: number) => {
  return num <= min ? min : num >= max ? max : num;
};

const calculateProgressThroughTimelistFromDates = (start: Date, end: Date) => {
  // @ts-ignore
  let progressInSeconds = (end - start) / 1000;
  return clamp(
    Math.ceil((progressInSeconds / LENGTH_OF_MVP_TIMELIST_IN_SECS) * 100),
    0,
    100,
  );
};

const calculateActiveDateFromProgress = (start: Date, progress: number) => {
  let lengthOfTimelistInSeconds = 60 * 60 * 24 * 365 * 4;
  let numberOfSecondsThroughTimelist = (progress / 100) * lengthOfTimelistInSeconds;
  let numberOfMillisecondsThroughTimelist = numberOfSecondsThroughTimelist * 1000;
  return new Date(start.getTime() + numberOfMillisecondsThroughTimelist);
};

const TimelistProgress = (
  loggedIn: boolean,
  isEditing: boolean,
  isSaving: boolean,
  currProgress: number,
  listStart: number | null | undefined,
  activeDay: Date,
  startDateInput: string,
  updateProgress: any,
  handleEdit: any,
  handleSave: any,
  handleListStartChange: any,
) => {
  let undergraduateProgress = Math.min(25, currProgress);
  let sophomoreProgress = Math.max(0, Math.min(currProgress - 25, 25));
  let juniorProgress = Math.max(0, Math.min(currProgress - 50, 25));
  let seniorProgress = Math.max(0, Math.min(currProgress - 75, 25));

  let listStartStr = "Today";
  if (loggedIn && (listStart !== null)) {
    // NOTE: We multiply by one thousand to go from seconds to milliseconds.
    // Without this multiply, you get a date in 1970!
    // @ts-ignore
    listStartStr = new Date(listStart * 1000).toDateString();
  }

  let activeDayStr = activeDay.toDateString();
  if (loggedIn && listStart !== null) {
    activeDayStr = "Today";
  }

  let editButton = null;
  let saveButton = null;

  let listStartContents = <span>{listStartStr}</span>;
  if (isEditing) {
    const today = new Date();
    const year = today.getFullYear();
    const month = `${today.getMonth() + 1}`.padStart(2, "0");
    const day = `${today.getDate()}`.padStart(2, "0");
    const minListStart = "2015-01-01"; // TODO(Jonathon): Don't hardcode this
    const maxListStart = `${year}-${month}-${day}`;
    listStartContents = (
      <input
        id="list-start-input"
        max={maxListStart}
        min={minListStart}
        onChange={handleListStartChange}
        type="date"
        value={startDateInput}
        style={{ width: "80%" }}
      />
    );
    saveButton = (
      <Button
        onClick={handleSave}
        disabled={isSaving}
        variant="success"
        style={{ backgroundColor: "#1c801c" }}
        size="sm"
      >
        {isSaving ? "Saving..." : "Save"}
      </Button>
    );
  }

  let progressBarCursor = "pointer";
  if (loggedIn) {
    progressBarCursor = "auto";
    const editButtonText = isEditing ? "Cancel" : "Edit";
    const editButtonVariant = isEditing ? "danger" : "outline-secondary";
    editButton = (
      <ListGroup.Item>
        <Row className="text-left">
          <Col style={{ paddingRight: 0 }}>
            <ButtonGroup aria-label="Basic example">
              {saveButton}
              <Button onClick={handleEdit} size="sm" variant={editButtonVariant}>{editButtonText}</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }

  // Don't animate progress bars when logged in because the progress bar is not interactive when logged in.
  // Logged in users edit their progress via changing list start time.
  const isAnimated = !loggedIn;

  return (
    <Row>
      <Col>
        <ListGroup horizontal="sm" style={{ justifyContent: "space-between" }}>
          <ListGroup.Item style={{ paddingLeft: 0 }}>
            <label htmlFor="start">
              <strong>Start:</strong>
            </label>{" "}
            {listStartContents}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Active Day:</strong> {activeDayStr}
          </ListGroup.Item>
          <ListGroup.Item style={{ textAlign: "left", paddingRight: 0, width: "50%" }}>
            <strong>Periods:</strong>
            <Badge variant="warning" style={{ backgroundColor: BrandColours.Orange, marginRight: "1%" }}>
              Undergrad
            </Badge>
            <Badge variant="success" style={{ backgroundColor: BrandColours.Green, marginRight: "1%" }}>
              Sophomore
            </Badge>
            <Badge variant="info" style={{ backgroundColor: BrandColours.Blue, marginRight: "1%" }}>Junior</Badge>
            <Badge variant="primary" style={{ backgroundColor: BrandColours.Purple }}>Senior</Badge>
          </ListGroup.Item>
          {editButton}
        </ListGroup>
        <ProgressBar
          id={"progressBarRoot"}
          style={{ background: "#e8e8e8", cursor: progressBarCursor, marginTop: "5px" }}
          onClick={updateProgress}
        >
          <ProgressBar
            animated={isAnimated}
            style={{ backgroundColor: BrandColours.Orange }}
            now={undergraduateProgress}
            key={1}
          />
          {sophomoreProgress > 0
            ? (
              <ProgressBar
                animated={isAnimated}
                style={{ backgroundColor: BrandColours.Green }}
                now={sophomoreProgress}
                key={2}
              />
            )
            : null}
          {juniorProgress > 0
            ? (
              <ProgressBar
                animated={isAnimated}
                style={{ backgroundColor: BrandColours.Blue }}
                now={juniorProgress}
                key={3}
              />
            )
            : null}
          {seniorProgress > 0
            ? (
              <ProgressBar
                animated={isAnimated}
                style={{ backgroundColor: BrandColours.Purple }}
                now={seniorProgress}
                key={4}
              />
            )
            : null}
        </ProgressBar>
      </Col>
    </Row>
  );
};

/**
 * Users with a fast internet connection can still get a really slow load time if the Lambda function
 * is 'cold' and needs to warm up. In those cases I want to show a special message to communicate that the
 * web app isn't actually slow as shit. It's just the Lambda function having zero warm instances.
 *
 * If this component didn't check sessionStorage users with a slow internet connection would keep getting
 * the 'slow lambda' message and that would be misleading and annoying for them.
 */
const SlowLambdaLoadingMessage = () => {
  const [show, setShow] = useState(false);

  useEffect(
    () => {
      let timer1 = setTimeout(() => setShow(true), 1600);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(timer1);
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    [],
  );

  let displayLoadingMessage = false;
  if (show) {
    if (sessionStorage.getItem("firstVisit") !== "1") {
      sessionStorage.setItem("firstVisit", "1");
      displayLoadingMessage = true;
    }
  }
  return displayLoadingMessage
    ? <div style={{ color: BrandColours.Purple, fontSize: "1.2em" }}>λ warming up…</div>
    : null;
};

const Feed = (props: FeedProps) => {
  const today = new Date();
  // truncate hours,mins,secs,millseconds to zero so that requests to backend are more cacheable
  today.setHours(0, 0, 0, 0);
  const loggedIn = props.loggedIn;
  const startDate = props.userSettings ? new Date(props.userSettings.start * 1000) : today;
  const initProgress = calculateProgressThroughTimelistFromDates(
    startDate,
    loggedIn ? today : DEFAULT_LOGGED_OUT_ACTIVE_DAY,
  );
  const updateFeedItems = props.updateFeedItems;

  let startDateFormattedForInput = "2019-09-09";
  if (props.userSettings) {
    const year = startDate.getFullYear();
    const month = `${startDate.getMonth() + 1}`.padStart(2, "0");
    const day = `${startDate.getDate()}`.padStart(2, "0");
    startDateFormattedForInput = `${year}-${month}-${day}`;
  }
  let endDate = new Date(startDate.getTime() + (LENGTH_OF_MVP_TIMELIST_IN_SECS * 1000));

  const [progress, setProgress] = useState(initProgress);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [startDateInput, setStartDateInput] = useState(startDateFormattedForInput);

  // Logged in users always have their active day as the current day, so that the feed follows them through time.
  // Logged out users can mess around with the progress bar to change the active day and explore the list.
  const activeDay: Date = loggedIn
    ? today
    : calculateActiveDateFromProgress(today, progress);
  const listStart = props.listStart;

  const items: any = props.items.slice(0, MAX_ITEMS_IN_FEED);

  const handleEdit = (e: any) => {
    setIsEditing(!isEditing);
  };

  const handleSave = async (e: any) => {
    setIsSaving(true);
    const newListStartTime = new Date(startDateInput).getTime() / 1000;
    const newSettings = {
      ...props.userSettings,
      start: newListStartTime,
    };
    await props.updateCurrentWhitelistUser(newSettings);
    props.updateUserSettings(newSettings);
    setIsSaving(false);
    setIsEditing(false);
  };

  const handleListStartChange = (e: any) => {
    let newListStart = e.target.value;
    setStartDateInput(newListStart);
    if (newListStart) {
      let newProgress = calculateProgressThroughTimelistFromDates(new Date(newListStart), today);
      setProgress(newProgress);
    }
  };

  const updateProgress = (e: any) => {
    if (loggedIn) {
      // Logged in users edit their progress via changing list start time, so don't change progress
      // on click.
      return;
    }

    const clickedElem = e.target;
    let offsetX = e.nativeEvent.offsetX;

    // Handling the fact that the event offset is registered against the inner progress part
    // and not the overall element.
    let progressBarWidth = clickedElem.offsetWidth;
    if (clickedElem.id !== "progressBarRoot") {
      progressBarWidth = clickedElem.parentNode.offsetWidth;
      let parentOffset = clickedElem.offsetLeft;
      offsetX += parentOffset;
      offsetX -= 15; // There's a small amount of extra fat in the offsetX for some reason.
    }

    let userSetProgress = Math.ceil((offsetX / progressBarWidth) * 100);
    // For logged out users I only want to show a limited preview of the list's contents
    // so I round to the nearest 10.
    if (!loggedIn) {
      if (userSetProgress <= 4) {
        // The below 'else' calculation is biased away from 0, making it hard to see the start of the list.
        // Clamp numbers close to 0 to 0 so it's easy.
        userSetProgress = 0;
      } else {
        userSetProgress = Math.ceil(userSetProgress / 10) * 10;
      }
    }
    setProgress(userSetProgress);
  };

  // @ts-ignore
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      updateFeedItems(startDate, endDate, progress, setIsLoading);
    }
    return () => mounted = false;
  }, [progress]); // once the progress is updated useEffect executes

  let articleListComponent;
  if (isLoading) {
    articleListComponent = (
      <div>
        <Spinner
          animation="border"
          role="status"
          variant={"primary"}
          style={{ marginBottom: "20px" }}
        />
        <SlowLambdaLoadingMessage />
      </div>
    );
  } else if (items.length === 0) {
    articleListComponent = (
      <p>
        <span role="img" aria-label="surprise face icon">😧</span> No Articles?! There's been a mistake.
      </p>
    );
  } else {
    articleListComponent = <ArticleList items={items} />;
  }

  return (
    <Container className="feed">
      <Row className="justify-content-md-center" style={{ marginTop: "3%" }}>
        <Col lg={10}>
          {TimelistProgress(
            loggedIn,
            isEditing,
            isSaving,
            progress,
            listStart,
            activeDay,
            startDateInput,
            updateProgress,
            handleEdit,
            handleSave,
            handleListStartChange,
          )}
        </Col>
      </Row>
      <Row className="justify-content-md-center" style={{ marginTop: "3%", marginBottom: "3%", borderRadius: "25px" }}>
        <Col lg={10}>
          {articleListComponent}
        </Col>
      </Row>
    </Container>
  );
};

export default Feed;
