import * as React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";

interface FooterProps {}

class Pitch extends React.Component<FooterProps, {}> {
  render() {
    const hackernewsTooltip = (props: any) => (
      <Tooltip id="button-tooltip" {...props}>
        Vote-based link aggregators cannot support structured learning.
      </Tooltip>
    );

    const collegeTooltip = (props: any) => (
      <Tooltip id="button-tooltip" {...props}>
        Too often these days colleges have their eyes on graduation rates and revenue instead of your education.
      </Tooltip>
    );

    return (
      <Container>
        {/* Emulate the 'use without login' exp of https://repl.it/languages/java10 */}
        <Row className="justify-content-md-center">
          <Col lg={10}>
            <h1 style={{ textAlign: "left" }}>An educational content feed for your long-term engineering goals.</h1>
            <br />
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={hackernewsTooltip}
            >
              <h3 style={{ textAlign: "left" }}>
                <span style={{ color: "rgb(227, 96, 10)" }}>Hackernews</span>{" "}
                has the right content, but rarely at the right time.
              </h3>
            </OverlayTrigger>
            <br />
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={collegeTooltip}
            >
              <h3 style={{ textAlign: "left" }}>
                <span role="img" aria-label="college icon">🏫</span> College has the structure, but with a lot of gaps.
              </h3>
            </OverlayTrigger>
            <br />
            <h3 style={{ textAlign: "left" }}>
              This is Whitelist. A goal-oriented, calendar aware content feed.
            </h3>
          </Col>
        </Row>
        <Row className="justify-content-md-center" style={{ marginTop: "3%" }}>
          <Col lg={10}>
            <h2 style={{ textAlign: "left" }}>
              See How It Works <span role="img" aria-label="hand pointing down">👇</span>
            </h2>
            <p style={{ textAlign: "left" }}>
              The list is set to begin on a certain day, and as you move into the future new content is delivered to you
              at the time you should need it.
            </p>
            <p style={{ textAlign: "left" }}>
              Below shows the list set to begin today, and you can adjust the progress bar to travel into the future and
              view the content shown at that future time.
            </p>
            <br />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Pitch;
