import { Auth } from "aws-amplify";
import * as React from "react";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import "./Header.css";
import { Overlay, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";

interface HeaderProps {
  currentUser: any;
}

interface HeaderView {
  currentUser: any;
  handleLogout?: any;
}

const LoggedOutView = (props: HeaderView) => {
  if (!props.currentUser) {
    return (
      <Nav className="justify-content-end" style={{ width: "100%" }}>
        <Nav.Item style={{ marginRight: "1%" }}>
          <Link to="/signup">
            <Button variant="outline-dark">Create Account</Button>
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/auth">
            <Button variant="outline-dark">Sign in</Button>
          </Link>
        </Nav.Item>
      </Nav>
    );
  }
  return null;
};

const LoggedInView = (props: HeaderView) => {
  const [showEmail, setShowEmail] = useState(false);
  const target = useRef(null);

  if (props.currentUser) {
    const userEmail = props.currentUser.attributes.email;
    return (
      <Nav className="justify-content-end" style={{ width: "100%" }}>
        <Nav.Item>
          <Button ref={target} variant="secondary" onClick={props.handleLogout}>
            Sign Out
          </Button>
        </Nav.Item>
        <Nav.Item style={{ paddingLeft: "10px" }}>
          <Button ref={target} variant="outline-dark" onClick={() => setShowEmail(!showEmail)}>
            <span role="img" aria-label="user icon">🤓</span>
          </Button>
          <Overlay target={target.current} show={showEmail} placement="bottom-start">
            {(props) => (
              <Tooltip id="overlay-example" {...props}>
                {userEmail}
              </Tooltip>
            )}
          </Overlay>
        </Nav.Item>
      </Nav>
    );
  }

  return null;
};

const Header = (props: HeaderProps) => {
  const handleLogout = async () => {
    console.log("Log out requested");
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const appTitle = "whitelist.sh";

  return (
    // NavBar has its z-index lowered from ~1030 to 66 so that the AWS Amplify
    // toast components show up properly. The toast z-index is 99.
    <Navbar className="navbar" fixed="top" style={{ zIndex: 66 }}>
      <Link to="/">
        <img
          alt="whitelist app logo"
          src="https://whitelist-assets.s3.amazonaws.com/0.75x/whitelist-logo-dark-ldpi.png"
          height={75}
          width={75}
        />
      </Link>
      <Navbar.Brand href="/">{appTitle}</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <LoggedOutView currentUser={props.currentUser} />
      <LoggedInView
        currentUser={props.currentUser}
        handleLogout={handleLogout}
      />
    </Navbar>
  );
};

export default Header;
