import { format, formatDistanceToNow } from "date-fns";
import * as React from "react";

import "./Item.css";

import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";

import BrandColours from "../../types/BrandColours";
import { Item as ItemProps } from "../../types/Domain";

const itemTypeIcon = (type: string | undefined) => {
  switch (type) {
    case undefined:
      return null;
    case "code":
      return null;
    case "practice-problem":
      return <span style={{ marginRight: "5px" }} role="img" aria-label="muscle arm icon">💪</span>;
    case "blog-post":
      return <span style={{ marginRight: "5px" }} role="img" aria-label="paper with pencil icon">📝</span>;
    case "textbook":
      return <span style={{ marginRight: "5px" }} role="img" aria-label="textbooks icon">📚</span>;
    case "book":
      return <span style={{ marginRight: "5px" }} role="img" aria-label="book icon">📖</span>;
    case "paper":
      return <span style={{ marginRight: "5px" }} role="img" aria-label="academic paper icon">📃</span>;
    case "article":
      return <span style={{ marginRight: "5px" }} role="img" aria-label="article icon">📰</span>;
    case "video":
      return <span style={{ marginRight: "5px" }} role="img" aria-label="movie icon">🍿</span>;
    case "tool":
      return <span style={{ marginRight: "5px" }} role="img" aria-label="tools icon">🛠</span>;
    case "podcast":
      return <span style={{ marginRight: "5px" }} role="img" aria-label="podcast icon">🔉</span>;
    default:
      return null;
  }
};

const choose = (choices: string | any[]) => {
  let index = Math.floor(Math.random() * choices.length);
  return choices[index];
};

const SelfItem = (props: ItemProps): JSX.Element => {
  const borderColor = choose([
    BrandColours.Yellow,
    BrandColours.Green,
    BrandColours.Orange,
    BrandColours.Red,
    BrandColours.Blue,
    BrandColours.Purple, // Note: No 'ink' used
  ]);
  return (
    <Card style={{ borderColor: borderColor }} className="item">
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text>
          {props.description}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const Item: React.SFC<ItemProps> = (props: ItemProps): JSX.Element => {
  let itemScheduleTime;
  if (props.schedule_time) {
    let preText = "in ";
    let postText = "";
    if (new Date(props.schedule_time) <= new Date()) {
      preText = "";
      postText = " ago";
    }
    itemScheduleTime = (
      <span>
        {format(new Date(props.schedule_time), "PP")}
        <em>
          {" ("}
          <span>{preText}{formatDistanceToNow(new Date(props.schedule_time))}{postText}</span>)
        </em>
      </span>
    );
  } else {
    itemScheduleTime = null;
  }

  const hnDiscussionLink = props.annotations.get("hn_discussion");
  const itemType = props.annotations.get("type");
  const itemCategory = props.annotations.get("category");
  const itemYear = props.annotations.get("year");
  const itemIsPartOfCSCurriculum = props.annotations.get("curriculum") !== undefined;

  if (props.link.startsWith("https://whitelist.sh/self")) {
    return SelfItem(props);
  }

  let cardBackground = "light";
  if (itemCategory === "hackernews") {
    cardBackground = "#FFE1B2";
  }

  return (
    <Card border="secondary" className="item" style={{ backgroundColor: cardBackground }}>
      <Card.Body>
        <Card.Title>
          <a style={{ color: "#222222" }} href={props.link} target="_blank" rel="noopener noreferrer">{props.title}</a>
        </Card.Title>
        <Card.Text>
          {props.description}
        </Card.Text>
        {hnDiscussionLink !== undefined
          ? (
            <Card.Link target="_blank" rel="noopener noreferrer" href="https://news.ycombinator.com/item?id=26617801">
              Hackernews discussion
            </Card.Link>
          )
          : null}
      </Card.Body>
      <Card.Footer className="text-muted">
        {itemType
          ? <Badge variant="dark" style={{ marginRight: "1%" }}>{itemTypeIcon(itemType)}{itemType}</Badge>
          : null}
        {itemCategory ? <Badge variant="dark" style={{ marginRight: "1%" }}>{itemCategory}</Badge> : null}
        {itemYear ? <Badge variant="dark" style={{ marginRight: "1%" }}>{itemYear}</Badge> : null}
        {itemIsPartOfCSCurriculum
          ? (
            <Badge variant="light" style={{ backgroundColor: "white", marginRight: "1%" }}>
              <span role="img" aria-label="graduates hat icon">🎓</span> Core Curriculum
            </Badge>
          )
          : null}
        <span style={{ float: "right" }} className="mb-2 text-muted">
          {itemScheduleTime}
        </span>
      </Card.Footer>
    </Card>
  );
};

export default Item;
