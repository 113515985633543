import * as React from "react";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import "./FreqAskedQuestionsPage.css";
import BrandColours from "../../types/BrandColours";

const choose = (choices: string | any[]) => {
  let index = Math.floor(Math.random() * choices.length);
  return choices[index];
};

const FaqItem = (props: { question: string; answer: JSX.Element | string }): JSX.Element => {
  const borderColor = choose([
    BrandColours.Green,
    BrandColours.Orange,
    BrandColours.Red,
    BrandColours.Blue,
    BrandColours.Purple, // Note: No 'ink' or 'yellow' used.
  ]);
  return (
    <Card style={{ borderColor: borderColor }} className="item">
      <Card.Body>
        <Card.Title>{props.question}</Card.Title>
        <Card.Text>
          {props.answer}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const FreqAskedQuestionsPage: React.SFC = (): JSX.Element => {
  return (
    <Container>
      <Row style={{ marginBottom: "2%" }}>
        <Col md="auto">
          <h1>Frequently Asked Questions</h1>
        </Col>
      </Row>
      <Row>
        <Col md="auto">
          <FaqItem
            question={"Is it expected that everything in the list be learned and understood?"}
            answer={"God no! I'm six years into studying software engineering and couldn't claim knowledge of more than half the list's total content."
              + " It would be a truly exceptional person who could digest the whole list in four years."
              + " What the breadth of the list offers isn't a must-know curriculum but the opportunity to avoid devilish unknown-unknowns."
              + " If you proceed from start to finish through the list you can be assured that you'll at least be aware of the most important information and knowledge in the software field, and that awareness is the precursor to full literacy and mastery."}
          />
          <FaqItem
            question={"How was the list created?"}
            answer={"Manually, over many dozens of hours. Whitelist.sh promises a lovingly and meticulously curated learning curriculum and that can only be delivered by having a software professional perform the slow collection, filtering, and organising process."}
          />
          <FaqItem
            question={"Why does this list include 'non-technical' things like documentaries, T.V shows, history, and humour?"}
            answer={
              <p>
                The software and computing field, and even programming itself, is so much more than the mechanics of
                having computers store and manipulate data. The list's author subscribes to the idea that software and
                computing is best understood by gathering as many angles and perspectives as one can. In line with
                American educator E.D Hirsch's theory of{" "}
                <a href={"https://en.wikipedia.org/wiki/Cultural_literacy"} rel="noopener noreferrer">
                  cultural literacy
                </a>, the list's breadth emanates from a belief that the software industry is best engaged with as
                being, all at once: political, historical, social, economic, academic, artistic, and technical.
              </p>
            }
          />
          <FaqItem
            question={"Is this site designed only for undergraduate college students?"}
            answer={"Not at all. The list is designed for any self-learner, whether currently enrolled in a college CS degree or not."
              + " The list is structured like a four year undergraduate course partly because it makes it complementary to a enrolled student's coursework,"
              + " but also partly because the author believes that this structure is still"
              + " the best available for people looking to build knowledge and competency in software for the long-term."}
          />
          <FaqItem
            question={"Can I contribute an addition to the list, or suggest a deletion?"}
            answer={"I'd love to receive additions, or criticisms of items I've included in the list."
              + " I don't want to put my email here, but I'm sure you can figure out how to reach me over email or Twitter."}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default FreqAskedQuestionsPage;
