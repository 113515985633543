import * as React from "react";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import "./Error.css";

const Error = (): JSX.Element => {
  return (
    <Container className="error-container">
      <Row className="justify-content-md-center">
        <Col md="auto" style={{ flexDirection: "row", display: "flex" }}>
          <Card style={{ width: "18rem" }}>
            <Card.Body>
              <h3>
                <span role="img" aria-label="anger sign icon">💢</span> Error <strong>500</strong>
              </h3>
              <Card.Text>
                Sorry, something's gone wrong on our end. Please try again later.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Error;
