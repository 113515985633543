import * as React from "react";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import "./Footer.css";
import { Link } from "react-router-dom";

interface FooterProps {}

class Footer extends React.Component<FooterProps, {}> {
  render() {
    const cardStyle = {
      width: "18em",
      border: "none",
      background: "#fff",
      color: "#222222",
    };

    return (
      <Container fluid className="footer-bg footer">
        <Row className="justify-content-md-center" xs={1} md={2}>
          <Col md="auto" lg="auto">
            <Card border="light" style={{ ...cardStyle, display: "inline-block" }}>
              <h5>
                whitelist.sh <span>APP</span>
              </h5>
            </Card>
          </Col>
          <Col md="auto" lg="auto">
            <Card border="light" style={cardStyle}>
              <Card.Text>
                Made by{" "}
                <a target="_blank" rel="noopener noreferrer" href={"https://github.com/thundergolfer"}>
                  <strong>@thundergolfer</strong>
                </a>{" "}
                to help you become a great engineer. no bullshit.<br />no shortcuts.
              </Card.Text>
              <Card.Text>
                <Link to="/">
                  <strong>Home</strong>
                </Link>
                <Link to="/faq" style={{ marginLeft: "1.25em" }}>
                  <strong>F.A.Q</strong>
                </Link>
                <Link to="/signup" style={{ marginLeft: "1.25em" }}>
                  <strong>Signup</strong>
                </Link>
              </Card.Text>
              <Card.Text>
                Sydney, Australia<br />
                © 2020 Jonathon Belotti
              </Card.Text>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Footer;
