/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:a33ac3cb-48c2-46bb-a4f1-6be021974b74",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_1LlER9KyM",
  "aws_user_pools_web_client_id": "7pasb9u0gpg429ijhk45fqr2lf",
  "oauth": {},
};

export default awsmobile;
