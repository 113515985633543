import "bootstrap/dist/css/bootstrap.css";

import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Authenticator } from "@aws-amplify/ui-react";
import * as React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import { BrowserRouter, Redirect, useLocation } from "react-router-dom";
import { Route, Switch } from "react-router-dom";

import Auth from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useEffect, useState } from "react";

import "./App.css";
import "@aws-amplify/ui-react/styles.css";

import { FunctionComponent } from "react";
import Spinner from "react-bootstrap/Spinner";
import { FourOFourPage } from "../../pages/four-o-four";
import { FreqAskedQuestionsPage } from "../../pages/freq-asked-questions";
import { Footer } from "../footer";
import { Header } from "../header";
import Root, { API_BASE, USER_SETTINGS_LOCAL_STORAGE_KEY_PREFIX } from "../Root";
import { Subscribe } from "../subscribe";

// START copy-paste from https://github.com/aws-amplify/amplify-js/issues/3640#issuecomment-760935908
export interface UseAuthHookResponse {
  currentUser: CognitoUser | null;
  userSettings: WhitelistUserSettings | null;
  setUserSettings: any;
  signIn: () => void;
  signOut: () => void;
}

interface WhitelistUserSettings {
  start: number;
  end: number;
  feed_start: number;
  feed_end: number;
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

// Support multiple users per browser instance.
const localStorageUserSettingsKey = (username: string) => {
  return `${USER_SETTINGS_LOCAL_STORAGE_KEY_PREFIX}_${username}`;
};

const getCurrentUser = async (): Promise<CognitoUser | null> => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch {
    // currentAuthenticatedUser throws an Error if not signed in
    return null;
  }
};

// TODO(Jonathon): "User does not exist" infinite request loop happens on REFRESH_TOKEN_AUTH
// Clearing local storage data for whitelist.sh in Chrome fixed the issue.
const getCurrentWhitelistUser = async (user: CognitoUser): Promise<WhitelistUserSettings> => {
  // If the user settings are already in localStorage don't bother with API call to backend.
  let localUserSettings = localStorage.getItem(localStorageUserSettingsKey(user.getUsername()));
  if (localUserSettings !== null) {
    return JSON.parse(localUserSettings);
  }

  // @ts-ignore
  let accessToken = user.getSignInUserSession().getAccessToken();
  let jwt = accessToken.getJwtToken();
  const resp = await fetch(
    `${API_BASE}user/${user?.getUsername()}`,
    {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${jwt}`,
      },
    },
  );
  if (!resp.ok) {
    throw resp.status;
  }
  const data = await resp.json();
  // Update localStorage cache after API call.
  localStorage.setItem(localStorageUserSettingsKey(user.getUsername()), JSON.stringify(data));
  return data;
};

const updateCurrentWhitelistUser = async (newUserSettings: any) => {
  const currSession = await Auth.currentSession();
  let accessToken = currSession.getAccessToken();
  let jwt = accessToken.getJwtToken();
  let username = accessToken.payload.username;

  const userEndpointURL = `${API_BASE}user/${username}`;
  const resp = await fetch(
    userEndpointURL,
    {
      method: "PATCH",
      mode: "cors",
      body: JSON.stringify(newUserSettings),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${jwt}`,
      },
    },
  );
  const data = await resp.json();
  // Update localStorage cache after update API call.
  localStorage.setItem(localStorageUserSettingsKey(username), JSON.stringify(newUserSettings));
  return data;
};

const createUserSettingsInBackend = async (postData: any) => {
  const currSession = await Auth.currentSession();
  let accessToken = currSession.getAccessToken();
  let jwt = accessToken.getJwtToken();
  let username = accessToken.payload.username;
  const createUserURL = `${API_BASE}user/${username}`;
  const resp = await fetch(
    createUserURL,
    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(postData),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${jwt}`,
      },
    },
  );
  return await resp.json();
};

const useAuth = (): UseAuthHookResponse => {
  const [currentUser, setCurrentUser] = useState<CognitoUser | null>(null);
  const [userSettings, setUserSettings] = useState<WhitelistUserSettings | null>(null);

  useEffect(() => {
    const updateUser = async () => {
      const cognitoUser = await getCurrentUser();
      // @ts-ignore
      setCurrentUser(cognitoUser);
      // @ts-ignore
      if (cognitoUser !== null) {
        try {
          const userSettingsData = await getCurrentWhitelistUser(cognitoUser);
          setUserSettings(userSettingsData);
        } catch (error) {
          console.log(`error: ${error}`);
          if (error === 404) {
            let lengthOfTimelistInSeconds = 60 * 60 * 24 * 365 * 4;
            const start = Math.ceil(new Date().getTime() / 1000);
            const postData = {
              start: start,
              end: start + lengthOfTimelistInSeconds,
              feed_start: start,
              feed_end: 4, // doesn't matter
              aws_cognito_username: cognitoUser.getUsername(),
            };
            await createUserSettingsInBackend(postData);
            setUserSettings(postData);
          } else {
            console.log(`Failed to retrieve user settings with error code ${error}`);
          }
        }
      } else {
        setUserSettings(null);
      }
    };
    Hub.listen("auth", updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove("auth", updateUser);
  }, []);

  const signIn = () => Auth.federatedSignIn();

  const signOut = () => Auth.signOut();

  return { currentUser, userSettings, setUserSettings, signIn, signOut };
};

// END copy-paste from https://github.com/aws-amplify/amplify-js/issues/3640#issuecomment-760935908

// TODO(Jonathon): To solve the 'blank page while Amplify loads' problem just display a spinner *under* the sign-up component so that when it finally
// shows up the sign-up component just covers the spinner.
// Yes, it's a hack.

const App: FunctionComponent = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();
  const { currentUser, userSettings, setUserSettings, signOut } = useAuth();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      // @ts-ignore
      setAuthState(nextAuthState);
      // @ts-ignore
      setUser(authData);
      if (nextAuthState === AuthState.SignedOut) {
        signOut();
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App">
        <Header currentUser={currentUser} />
        <Jumbotron fluid style={{ marginTop: "100px" }}>
          <Switch>
            <Route exact path="/auth">
              {authState === AuthState.SignedIn && user
                ? <Redirect to="/" />
                : (
                  <Authenticator initialState="signIn" loginMechanisms={["email"]}>
                    {() => <Redirect to="/" />}
                  </Authenticator>
                )}
            </Route>
            <Route exact path={"/signup"}>
              {authState === AuthState.SignedIn && user
                ? <Redirect to="/" />
                : (
                  <Authenticator initialState="signUp" loginMechanisms={["email"]}>
                    {() => <Redirect to="/" />}
                  </Authenticator>
                )}
            </Route>
            <Route exact path="/subscribe">
              <Subscribe currentUser={currentUser} />
            </Route>
            <Route exact path="/faq">
              <FreqAskedQuestionsPage />
            </Route>
            <Route exact path="/">
              {currentUser !== null && userSettings == null
                ? (
                  <Spinner
                    animation={"border"}
                    role={"status"}
                    variant={"primary"}
                    style={{ marginBottom: "20px" }}
                  />
                )
                : (
                  <Root
                    loggedIn={currentUser !== null}
                    userSettings={userSettings}
                    updateCurrentWhitelistUser={updateCurrentWhitelistUser}
                    updateUserSettings={setUserSettings}
                  />
                )}
            </Route>
            <Route>
              <FourOFourPage />
            </Route>
          </Switch>
        </Jumbotron>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
