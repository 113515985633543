import * as React from "react";

import "./Subscribe.css";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51IZaoRKBfWcynGvxttwtC463nDqiqkKU0UNUtWT7X86nYfchiCdjgV1GrpvT6qbBg7dsmnO6nyM7iP9BuipEOnFx001OLP7ajT",
);

interface SubscribeProps {
  currentUser: any;
}

const Subscribe = (props: SubscribeProps) => {
  if (process.env.NODE_ENV !== "production") {
    return null;
  }

  return (
    // NavBar has its z-index lowered from ~1030 to 66 so that the AWS Amplify
    // toast components show up properly. The toast z-index is 99.
    <Elements stripe={stripePromise}>
      <p>The subscribe component</p>
    </Elements>
  );
};

export default Subscribe;
