enum BrandColours {
  Yellow = "#f4ff0a",
  Green = "#078a00",
  Orange = "#f7931e",
  Red = "#c1272d",
  Blue = "#0071bc",
  Purple = "#2e3192",
  Ink = "#002130",
}

export default BrandColours;
